<template>
  <div id="app">
    <a-layout>
      <a-layout-header class="header" style="line-height:55px;height:55px">
        <div class="logo">
          <a href="/">
            <img src="@/assets/prosparency-splash-logo-dark.png" height="50px"/>
          </a>
        </div>
        <a-menu
          theme="dark"
          align="right"
          mode="horizontal"
          :style="{ lineHeight: '55px' }"
        >
          <a-menu-item key="1" v-if="user.name">
            <span class="nav-text">{{ user.name }}</span>
          </a-menu-item>
          <!-- Dropdown for clients -->
          <a-menu-item key="2" v-if="user.profile && user.profile.role === 'admin'">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                {{ selectedClient && selectedClient.display_name }}
                <a-icon type="down"/>
              </a>
              <a-menu slot="overlay">
                <a-menu-item
                    v-for="(client, index) in clients"
                    :key="index"
                    @click="setClient(client)"
                >
                  {{ client.display_name }}
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-menu-item>
          <!-- Logout button -->
          <a-menu-item key="3">
            <a-icon type="logout" />
            <span class="nav-text" @click="logout">Logout</span>
          </a-menu-item>
         </a-menu>
      </a-layout-header>
      <router-view/>
    </a-layout>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #2c3e50; */
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.header {
  height:50px;
}

.logo {
  display:inline;
  max-width:100px;
  float:left;
}
</style>

<script>

const fb = require('./firebaseConfig.js')

export default {
  data() {
    return {
      collapsed: false,
      clients: [],
    };
  },
  watch: {
    '$auth.user': function (newUser) {
      console.log('User changed', newUser);
      this.loadClients();
    }
  },
  computed: {
    user() {
      return this.$auth.user;
    },

    selectedClient() {
      let client_name = this.$auth.user && this.$auth.user.profile ? this.$auth.user.profile.client : '';
      if (!client_name) {
        return null;
      }
      let client = this.clients.find(c => c.name === client_name);
      if (!client) {
        return null;
      }
      return client;
    },
  },
  methods: {
    logout: function (){
      this.$auth.logout();
      //fb.auth.signOut()
    },
    loadClients: async function () {
      const res = await this.$kysapi.get('api/clients', this.$auth);
      this.clients = res.data;
    },
    setClient: async function (client) {
      await this.updateImpersonationClient(client);
      alert('Impersonated to Client: ' + client.name + '. Refreshing page now.');
      window.location.reload();
      // You can add more logic here to update the client information in the app
    },
    updateImpersonationClient: async function (client) {
      await this.$kysapi.post('api/impersonate', {client_name: client.name}, this.$auth)
    }
  }
};
</script>
